import { type CSSProperties, useMemo, useRef, useState } from 'react';
import {
  type ConnectDragSource,
  DndProvider,
  useDrag,
  useDrop,
} from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { canMove } from '../../utils/dnd';

export interface DraggableItem {
  id: string | number;
}

export type RenderProps<T extends DraggableItem> = {
  item: T;
  index: number;
  isDragging: boolean;
  drag: ConnectDragSource;
  isHover: boolean;
  ref: React.RefObject<HTMLDivElement>;
  style: CSSProperties;
};

export type RenderDragDropItem<T extends DraggableItem> = (
  props: RenderProps<T>
) => JSX.Element;

interface DraggedItem extends DraggableItem {
  originalIndex: number;
  index: number;
}

interface HoverAction {
  id: string | number;
  fromIndex: number;
  toIndex: number;
}

function DragDropItem<T extends DraggableItem>(props: {
  item: T;
  type: string;
  index: number;
  onHover: (action: HoverAction) => void;
  onDragEnd: (isDropped: boolean) => void;
  render: RenderDragDropItem<T>;
}) {
  const { item, type, index, onHover, onDragEnd, render } = props;

  const ref = useRef<HTMLDivElement>(null);

  const [{ isDragging }, drag, dragPreview] = useDrag({
    type: type,
    item: (): DraggedItem => {
      return { id: item.id, originalIndex: index, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end() {
      onDragEnd(true);
    },
  });

  const [{ isHover }, drop] = useDrop({
    accept: type,
    collect: (monitor) => ({
      isHover: monitor.isOver(),
    }),
    hover(source: DraggedItem, monitor) {
      if (!ref.current) return;

      const hoverIndex = index;
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      if (canMove(source.index, hoverIndex, hoverBoundingRect, monitor)) {
        onHover({
          id: source.id,
          fromIndex: source.originalIndex,
          toIndex: index,
        });
        source.index = hoverIndex;
      }
    },
  });

  dragPreview(drop(ref));

  return render({
    item,
    index,
    isDragging,
    drag,
    isHover,
    ref,
    style: isDragging ? { opacity: 0.4 } : {},
  });
}

export function DragDropList<T extends DraggableItem>(props: {
  type: string;
  items: T[];
  onMove?: (from: number, to: number) => void;
  render: RenderDragDropItem<T>;
}): JSX.Element | null {
  const [action, setAction] = useState<HoverAction | null>(null);

  const handleHover = (next: HoverAction) => {
    setAction(next);
  };

  const handleDragEnd = (isDropped: boolean) => {
    if (!action) return;
    if (isDropped && action.fromIndex !== action.toIndex) {
      props.onMove?.(action.fromIndex, action.toIndex);
    }
    setAction(null);
  };

  const previewItems = useMemo(() => {
    if (!action) return props.items;

    const res = [...props.items];
    const item = res[action.fromIndex];
    res.splice(action.fromIndex, 1);
    res.splice(action.toIndex, 0, item);
    return res;
  }, [action, props.items]);

  return (
    <DndProvider backend={HTML5Backend}>
      {previewItems.map((item, index) => (
        <DragDropItem
          key={item.id}
          type={props.type}
          item={item}
          index={index}
          onHover={handleHover}
          onDragEnd={handleDragEnd}
          render={props.render}
        />
      ))}
    </DndProvider>
  );
}
